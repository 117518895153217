const fuelType = [
    {
        label: "GASOLINE",
    },
    {
        label: "DIESEL",
    },
    {
        label: "ELECTIR",
    },
    {
        label: "HYBRID",
    },
    {
        label: "GAS",
    },
];


export default fuelType;
const typeSelection =
{
    SKODA: [
        { label: "Scala", },
        { label: "Kamiq", },
        { label: "Fabia", },
        { label: "Karoq", },
        { label: "Kodiaq", },
        { label: "Octavia", },
        { label: "Superb", },
        { label: "Citigo", },
        { label: "Enyaq", },
        { label: "Rapid", },
    ],
    BMW: [
        { label: "1 Series", },
        { label: "2 Series", },
        { label: "3 Series", },
        { label: "4 Series", },
        { label: "5 Series", },
        { label: "6 Series", },
        { label: "7 Series", },
        { label: "8 Series", },
        { label: "x1", },
        { label: "x2", },
        { label: "x3", },
        { label: "x4", },
        { label: "x5", },
        { label: "x6", },
        { label: "x7", },
        { label: "xm", },
    ],
    MERCEDES: [
        { label: "A-Class", },
        { label: "B-Class", },
        { label: "C-Class", },
        { label: "E-Class", },
        { label: "S-Class", },
        { label: "G-Class", },
        { label: "CLA", },
        { label: "CLS", },
        { label: "GLA", },
        { label: "GLB", },
        { label: "GLC", },
        { label: "GLE", },
        { label: "GLS", },
    ],
    KIA: [
        { label: "Sportage", },
        { label: "Stinger", },
        { label: "Rio", },
        { label: "Sorento", },
        { label: "Soul", },
        { label: "K900", },
        { label: "Optima", },
        { label: "Forte", },
        { label: "Niro", },
    ],
    TOYOTA: [
        { label: "Prius", },
        { label: "Corolla", },
        { label: "Camry", },
        { label: "4Runner", },
        { label: "Yaris", },
        { label: "C-HR", },
        { label: "Land Cruiser", },
        { label: "Land Cruiser Prado", },
        { label: "Highlander", },
        { label: "RAV4", },

    ],
    VOLKSWAGEN: [
        { label: "Golf", },
        { label: "ID.3", },
        { label: "Polo", },
        { label: "Up", },
        { label: "Arteon", },
        { label: "Bora", },
        { label: "ID.7", },
        { label: "Jetta", },
        { label: "Passat", },
        { label: "Caddy", },
        { label: "Amarok", },
    ],
    SUBARU: [
        { label: "BRZ", },
        { label: "Impreza", },
        { label: "Legacy", },
        { label: "WRX", },
        { label: "Forester", },
    ],
    ISUZU: [
        { label: "D-Max", },
    ],
    DODGE: [
        { label: "Challenger", },
        { label: "Charger", },
        { label: "Viper", },
        { label: "RAM", },
        { label: "Durango", },
    ],
    FORD: [
        { label: "Fiesta", },
        { label: "Mustang", },
        { label: "Explorer", },
        { label: " F-150", },
        { label: "Expedition", },
        { label: "Edge", },
        { label: "Ranger", },
        { label: "Bronco", },
    ],
    ALL: [
        { label: "Scala", },
        { label: "Kamiq", },
        { label: "Fabia", },
        { label: "Karoq", },
        { label: "Kodiaq", },
        { label: "Octavia", },
        { label: "Superb", },
        { label: "Citigo", },
        { label: "Enyaq", },
        { label: "Rapid", },
        { label: "1 Series", },
        { label: "2 Series", },
        { label: "3 Series", },
        { label: "4 Series", },
        { label: "5 Series", },
        { label: "6 Series", },
        { label: "7 Series", },
        { label: "8 Series", },
        { label: "x1", },
        { label: "x2", },
        { label: "x3", },
        { label: "x4", },
        { label: "x5", },
        { label: "x6", },
        { label: "x7", }, { label: "xm", },
        { label: "A-Class", },
        { label: "B-Class", },
        { label: "C-Class", },
        { label: "E-Class", },
        { label: "S-Class", },
        { label: "G-Class", },
        { label: "CLA", },
        { label: "CLS", },
        { label: "GLA", },
        { label: "GLB", },
        { label: "GLC", },
        { label: "GLE", },
        { label: "GLS", },
        { label: "Sportage", },
        { label: "Stinger", },
        { label: "Rio", },
        { label: "Sorento", },
        { label: "Soul", },
        { label: "K900", },
        { label: "Optima", },
        { label: "Forte", },
        { label: "Niro", },
        { label: "Prius", },
        { label: "Corolla", },
        { label: "Camry", },
        { label: "4Runner", },
        { label: "Yaris", },
        { label: "C-HR", },
        { label: "Land Cruiser", },
        { label: "Land Cruiser Prado", },
        { label: "Highlander", },
        { label: "RAV4", },
        { label: "Golf", },
        { label: "ID.3", },
        { label: "Polo", },
        { label: "Up", },
        { label: "Arteon", },
        { label: "Bora", },
        { label: "ID.7", },
        { label: "Jetta", },
        { label: "Passat", },
        { label: "Caddy", },
        { label: "Amarok", },
        { label: "BRZ", },
        { label: "Impreza", },
        { label: "Legacy", },
        { label: "WRX", },
        { label: "Forester", },
        { label: "D-Max", },
        { label: "Fiesta", },
        { label: "Mustang", },
        { label: "Explorer", },
        { label: " F-150", },
        { label: "Expedition", },
        { label: "Edge", },
        { label: "Ranger", },
        { label: "Bronco", },
        { label: "Challenger", },
        { label: "Charger", },
        { label: "Viper", },
        { label: "RAM", },
        { label: "Durango", },
    ],
};


export default typeSelection;
const carManufacturer = [
    {
        label: "SKODA",
    },
    {
        label: "BMW",
    },
    {
        label: "MERCEDES",
    },
    {
        label: "KIA",
    },
    {
        label: "TOYOTA",
    },
    {
        label: "VOLKSWAGEN",
    },
    {
        label: "SUBARU",
    },
    {
        label: "ISUZU",
    },
    {
        label: "DODGE",
    },
    {
        label: "FORD",
    },
];




export { carManufacturer };
const carManufacturerStringArr = [
    "SKODA",
    "BMW",
    "MERCEDES",
    "KIA",
    "TOYOTA",
    "VOLKSWAGEN",
    "SUBARU",
    "ISUZU",
    "DODGE",
    "FORD",
];

export default carManufacturerStringArr